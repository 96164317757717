<template>
  <div>
    <Navbar v-if="$route.name !== 'Start'"/>
    <transition-page>
      <router-view/>
    </transition-page>
  </div>
</template>

<script>
import TransitionPage from '@/components/TransitionPage.vue'
import Navbar from '@/components/layout/Navbar'

export default {
  name: 'AdventureRoot',
  components: {
    Navbar,
    TransitionPage
  }
}
</script>